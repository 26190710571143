<template>
    <div class="settings">
        <SpinnerLoader v-if="loading"></SpinnerLoader>
        <div v-else>
            <div class="mt-6 mb-12">
                <h3 class="dashboard">Проверьте отправку бота</h3>
                <div>
                    <span>Бот привязан к номеру: </span> <span :key="'phoneKey' + phoneKey">{{ currentConnector.phone }}</span>
                </div>
                <span class="red-text"
                    ><strong>ВНИМАНИЕ!</strong> Отправка сообщений будет доступна только после истечения 15
                    минут</span
                >
                <v-row class="ma-0 mt-4" align="center">
                    <ValidationInput
                        class="ma-0 mt-2"
                        style="max-width: 250px"
                        :persistentLabel="true"
                        :rule="phoneValidator"
                        label="Номер телефона"
                        placeholder="+7 (987) 654-32-10"
                        v-model="testMessage.phone"
                    />
                    <v-btn
                        :disabled="!isValidPhone(testMessage.phone)"
                        outlined
                        class="outlined-btn"
                        style="margin-left: 100px; height: 40px"
                        @click="sendTestMesage"
                        :loading="testMessage.loading"
                        >Отправить тестовое сообщение</v-btn
                    >
                </v-row>
            </div>
            <div class="guide">
                <div>
                    <span
                        ><strong>ВАЖНО!</strong> Прочтите, пожалуйста, шаги, которых нужно придерживаться для
                        избежания блокировки номера <button @click="openHintSidebar"><a>WhatsApp</a></button>
                        <HintIcon :hintId="hintId" />
                    </span>
                    <v-checkbox
                        v-if="!isChange"
                        v-model="guideReaded"
                        label="Я ознакомился с инструкцией"
                    ></v-checkbox>
                </div>
            </div>
            <div class="auth-dead" v-if="!currentConnector.alive">
                <div class="qr-code-wrapper mx-0 my-10">
                    <div class="ml-4">
                        <span>Отсканируйте qr-код</span>
                    </div>
                    <SpinnerLoader v-if="qrLoading"></SpinnerLoader>
                    <canvas id="canvas" :class="{ hidden: qrLoading }"></canvas>
                </div>
            </div>
            <div class="controls" v-if="isChange">
                <v-btn
                    outlined
                    class="outlined-btn"
                    :loading="logoutLoading"
                    v-if="currentConnector.alive"
                    @click="logout"
                    >Отключить</v-btn
                >
                <div v-if="queueState" class="mt-10">
                    <div><strong>Задач в очереди:</strong></div>
                    <div>waiting: {{ queueState.jobs.waiting.length }}</div>
                    <div>delayed: {{ queueState.jobs.delayed.length }}</div>
                </div>
            </div>
            <v-row justify="end">
                <v-btn
                    @click="isChange ? goToProject() : createConnector()"
                    color="main"
                    class="mr-3 mt-2 white--text"
                    :disabled="applyBtnDisabled"
                    >Завершить</v-btn
                >
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { fetchConnector } from '@root/src/api/projects'
import { mapState } from 'vuex'
import SpinnerLoader from '../../SpinnerLoader.vue'
import { getQrCode } from '@root/src/api/connectors/whatsapp'
import QRCode from 'qrcode'
import websocket from '@root/src/websockets'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import { sendMassMail } from '@root/src/api/mailing'
import HintIcon from '../../HintIcon.vue'
import { useHintStore } from '@root/src/store/stores/hint'
import { mapStores } from 'pinia'
import api from '@root/src/api/api'
import phoneValidator, { isValidPhone } from '../../../helpers/validators/phoneValidator'
import ValidationInput from '../../inputs/ValidationInput.vue'
export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { SpinnerLoader, BaseInputOutlined, HintIcon, ValidationInput },
    data: () => ({
        phoneValidator,
        isValidPhone,
        currentConnector: null,
        loading: true,
        qrCode: null,
        guideReaded: false,
        hintId: 'knowledgeBase:1981',
        qrLoading: true,
        logoutLoading: false,
        queueState: null,
        testMessage: {
            phone: '',
            loading: false,
        },
        phoneKey: ref(0)
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        ...mapStores(useHintStore),
        currentConnectorId() {
            return this.isChange ? this.connector.connectorId : this.loggedAddingConnector.connectorId
        },
        applyBtnDisabled() {
            return this.isChange ? false : !this.guideReaded
        },
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },
        createConnector() {
            this.$parent.$emit('createConnector', {})
        },
        async sendTestMesage() {
            try {
                this.testMessage.loading = true
                const { data: sendMessageData, error: senMessageError } = await sendMassMail({
                    projectId: this.currentConnector.projectId,
                    connectorId: this.currentConnector.connectorId,
                    service: 'whatsapp',
                    messages: [
                        {
                            from: this.currentConnector.phone,
                            to: this.testMessage.phone.replace(/[^0-9]/g, ''),
                            text: 'Тестовое сообщение',
                            communicationId: null,
                        },
                    ],
                })
                if (senMessageError) {
                    this.$store.dispatch('callNotify', 'Ошибка при отправке сообщения (1)')
                    this.testMessage.loading = false
                    return
                }
                this.testMessage.loading = false
            } catch (err) {
                console.error(err)
                this.$store.dispatch('callNotify', 'Ошибка при отправке сообщения (2)')
                this.testMessage.loading = false
            }
        },
        openHintSidebar() {
            this.hintStore.openHintSidebar(this.hintId)
        },
        async logout() {
            this.logoutLoading = true
            const { error, data } = await api.connectors.whatsapp.logout(this.currentConnector.connectorId)
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка при отключении')
                this.logoutLoading = false
                return
            }
            this.goToProject()
        },
    },
    async mounted() {
        this.loading = true
        this.currentConnector = (await fetchConnector(this.currentConnectorId)).data
        this.queueState = await api.connectors.whatsapp
            .queueState(this.currentConnector.connectorId)
            .then(res => res.data)

        this.loading = false
        if (!this.currentConnector.alive) {
            this.qrLoading = true
            websocket.socket.on('WA_AUTH_SUCCESS', async payload => {
                this.currentConnector.alive = true
                this.currentConnector.phone = payload.phone
                this.phoneKey++
            })
            this.qrCode = (
                await getQrCode({
                    phone: this.currentConnector.phone,
                    projectId: this.currentConnector.projectId,
                    connectorId: this.currentConnector.connectorId,
                })
            ).data.QR
            this.qrLoading = false
            const canvas = document.getElementById('canvas')
            QRCode.toCanvas(canvas, this.qrCode, error => {
                if (error) this.$store.dispatch('callNotify', 'Ошибка при получении qr-кода')
            })
            this.qrLoading = false
        }
    },
    destroyed() {
        websocket.socket.off('WA_AUTH_SUCCESS')
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.cancel-btn
    border-color: #0000001F !important
.width-330
    width: 330px !important
.hidden
    display: none
.qr-code-wrapper
    display: flex
    flex-direction: column
.guide
    max-width: 600px
.red-text
    color: $red
</style>
